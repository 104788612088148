import { persist } from "effector-storage/local";
import { compileCodeFx } from "../code/model";
import {
  $activeApp,
  $activeAppWithState,
  $activeKeys,
  $assignedAddress,
  $contracts,
  $deploymentArgs,
  $error,
  $managementKey,
  $state,
  assignAdress,
  choseApp,
  closeApp,
  deploySmartContract,
  deploySmartContractFx,
  fetchBalanceFx,
  setAssignAddress,
  setDeploymentArg,
  setManagementPage,
  toggleActiveKey,
} from "./model";
import { combine, sample } from "effector";
import { isAddress } from "viem";
import { $wallet } from "../account-connector/models/model";

compileCodeFx.doneData.watch((apps) => {
  console.log("apps", apps);
});

compileCodeFx.failData.watch((error) => {
  console.error(error);
});

$contracts.on(compileCodeFx.doneData, (_, apps) => apps);
$contracts.reset(compileCodeFx.fail);

persist({
  store: $state,
  key: "contractState",
});

$activeApp.on(choseApp, (_, address) => address);
$activeApp.reset(closeApp);

$error.on(compileCodeFx.failData, (_, error) => `${error}`);
$error.reset(compileCodeFx.doneData);

$deploymentArgs.on(setDeploymentArg, (args, { key, value }) => {
  return {
    ...args,
    [key]: value,
  };
});
$deploymentArgs.reset($activeApp);

$assignedAddress.on(setAssignAddress, (_, address) => address);
$assignedAddress.reset($activeApp);

$state.on(
  sample({
    source: combine($assignedAddress, $activeApp, (address, app) => {
      return {
        address,
        app,
      };
    }),
    clock: assignAdress,
  }),
  (state, { address, app }) => {
    if (!app) {
      return state;
    }
    if (!isAddress(address)) {
      return state;
    }
    return {
      ...state,
      [app]: address,
    };
  },
);

export const $constructor = $activeAppWithState.map((app) => {
  if (!app) {
    return null;
  }
  for (const abi of app.abi) {
    if (abi.type === "constructor") {
      return abi;
    }
  }
  return null;
});

sample({
  source: combine($activeAppWithState, $deploymentArgs, $wallet, (app, args, wallet) => {
    if (!app) {
      return null;
    }
    if (!wallet) {
      return null;
    }
    let abiConstructor = null;
    for (const abi of app.abi) {
      if (abi.type === "constructor") {
        abiConstructor = abi;
        break;
      }
    }
    console.log("abiConstructor", abiConstructor);
    if (!abiConstructor) {
      return {
        app,
        args: [],
        wallet,
      };
    }

    const result: unknown[] = [];
    for (const input of abiConstructor.inputs) {
      let value: unknown;
      switch (true) {
        case input.type === "string": {
          value = value = input.name && input.name in args ? args[input.name] : "";
          break;
        }
        case input.type === "address": {
          value = value = input.name && input.name in args ? args[input.name] : "";
          break;
        }
        case input.type === "bool": {
          value = input.name && input.name in args ? !!args[input.name] : false;
          break;
        }
        case input.type.slice(0, 5) === "bytes": {
          value = input.name && input.name in args ? !!args[input.name] : "";
          break;
        }
        case input.type.slice(0, 3) === "int": {
          value = input.name && input.name in args ? BigInt(args[input.name]) : 0n;
          break;
        }
        default: {
          value = value = input.name && input.name in args ? args[input.name] : "";
          break;
        }
      }
      result.push(value);
    }
    return {
      app,
      args: result,
      wallet,
    };
  }),
  filter: combine($wallet, $activeApp, (wallet, app) => !!wallet && !!app),
  fn: (data) => {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    const { app, args, wallet } = data!;
    return {
      app,
      args,
      wallet,
    };
  },
  clock: deploySmartContract,
  target: deploySmartContractFx,
});

sample({
  source: $activeAppWithState,
  filter: $activeAppWithState.map((app) => !!app?.address),
  clock: choseApp,
  // biome-ignore lint/style/noNonNullAssertion: check in effector
  fn: (app) => app?.address!,
  target: fetchBalanceFx,
});
$state.on(deploySmartContractFx.doneData, (state, { app, address }) => {
  return {
    ...state,
    [app]: address,
  };
});

$managementKey.on(setManagementPage, (_, key) => key);
$managementKey.reset($activeApp);

$activeKeys.on(toggleActiveKey, (keys, key) => {
  return {
    ...keys,
    [key]: !keys[key],
  };
});

$activeKeys.reset($activeApp);

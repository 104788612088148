import { useUnit } from "effector-react";
import { $code, $error, changeCode, compile } from "./model";
import { Button, Card, CodeField } from "@nilfoundation/ui-kit";
import "./init";
import { useStyletron } from "baseui";
import { solidity } from "@replit/codemirror-lang-solidity";
import { type EditorView, type Extension, basicSetup } from "@uiw/react-codemirror";
import { useMemo } from "react";
import { fetchSolidityCompiler } from "../../services/compiler";
import { linter, type Diagnostic } from "@codemirror/lint";

export const Code = () => {
  const [code, isDownloading, errors] = useUnit([$code, fetchSolidityCompiler.pending, $error]);
  const [css] = useStyletron();
  console.log("errors", errors);
  const codemirrorExtensions = useMemo<Extension[]>(() => {
    const solidityLinter = (view: EditorView) => {
      const diagnostics: Diagnostic[] = errors.map((error) => {
        return {
          from: view.state.doc.line(error.line).from,
          to: view.state.doc.line(error.line).to,
          message: error.message,
          severity: "error",
        };
      });
      return diagnostics;
    };
    return [solidity, ...basicSetup(), linter(solidityLinter)];
  }, [errors]);
  return (
    <Card
      title="Contracts"
      overrides={{
        Root: {
          style: {
            backgroundColor: "#212121",
            width: "100%",
            maxWidth: "none",
            gridRow: "span 2",
          },
        },
      }}
    >
      <CodeField
        extensions={codemirrorExtensions}
        className={css({
          width: "100%",
          height: "calc(80vh - 200px)",
          minHeight: "400px",
          overflow: "auto",
        })}
        editable
        readOnly={false}
        code={code}
        onChange={(text) => {
          changeCode(`${text}`);
        }}
      />
      <Button isLoading={isDownloading} onClick={() => compile()}>
        Compile
      </Button>
    </Card>
  );
};

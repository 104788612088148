import { createEffect, createEvent, createStore } from "effector";
import type { App } from "../../types";

export const $code = createStore<string>("");
export const changeCode = createEvent<string>();
export const compile = createEvent();
export const $solidityVersion = createStore("soljson-v0.8.26+commit.8a97fa7a.js");
export const changeSolidityVersion = createEvent<string>();
export const $error = createStore<
  {
    message: string;
    line: number;
  }[]
>([]);
export const compileCodeFx = createEffect<
  {
    code: string;
    version: string;
  },
  App[]
>();

import { combine, createEffect, createEvent, createStore } from "effector";
import type { App } from "../../types";
import type { Address } from "abitype";
import { convertEthToWei, waitTillCompleted, type WalletV1 } from "@nilfoundation/niljs";

export type DeployedApp = App & {
  address: Address;
};

export const $contracts = createStore<App[]>([]);
export const $state = createStore<{ [code: string]: Address }>({});
export const $activeApp = createStore<`0x${string}` | null>(null);

export const choseApp = createEvent<`0x${string}`>();
export const closeApp = createEvent();

export const resetApps = createEvent();

export const $contractWithState = combine($contracts, $state, (contracts, state) => {
  const contractsWithAddress: (App & { address?: Address })[] = [];
  for (const contract of contracts) {
    if (state[contract.bytecode]) {
      contractsWithAddress.push({
        ...contract,
        address: state[contract.bytecode],
      });
    } else {
      contractsWithAddress.push(contract);
    }
  }
  return contractsWithAddress;
});

export const $error = createStore<string | null>(null);

export const $activeAppWithState = combine($activeApp, $contractWithState, (activeApp, state) => {
  if (activeApp === null) {
    return null;
  }

  return state.find((contract) => contract.bytecode === activeApp) || null;
});

export const $deploymentArgs = createStore<Record<string, string | boolean>>({});
export const setDeploymentArg = createEvent<{ key: string; value: string | boolean }>();
export const $assignedAddress = createStore<string>("");
export const setAssignAddress = createEvent<string>();

export const deploySmartContract = createEvent();
export const deploySmartContractFx = createEffect<
  {
    app: App;
    args: unknown[];
    wallet: WalletV1;
  },
  {
    address: `0x${string}`;
    app: `0x${string}`;
  }
>(async ({ app, args, wallet }) => {
  const salt = BigInt(Math.floor(Math.random() * 10000000000000000));
  console.log("Deploying", app, args);

  const { hash, address } = await wallet.deployContract({
    bytecode: app.bytecode,
    abi: app.abi,
    args,
    salt,
    shardId: 1,
    feeCredit: convertEthToWei(0.00001),
  });

  await waitTillCompleted(wallet.client, 1, hash);

  return {
    address,
    app: app.bytecode,
  };
});

export const assignAdress = createEvent();

export const $balance = createStore<string>("0");
export const $tokens = createStore<Record<`0x${string}`, bigint>>({});

export const fetchBalanceFx = createEffect<`0x${string}`, string>(async (address) => {
  console.log("Fetching balance for", address);
  return "0";
});

export const $managementKey = createStore<string>("read");
export const setManagementPage = createEvent<string>();

export const $activeKeys = createStore<Record<string, boolean>>({});

export const toggleActiveKey = createEvent<string>();

export const $callParams = createStore<Record<string, Record<string, string | boolean>>>({});

export const setParams = createEvent<{ function: string; key: string; params: Record<string, string | boolean> }>();

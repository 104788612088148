import { combine, createDomain } from "effector";
import type { WalletV1, Hex } from "@nilfoundation/niljs";

export const explorerTransactionDomain = createDomain("account-connector");

const createStore = explorerTransactionDomain.createStore.bind(explorerTransactionDomain);
export const createEvent = explorerTransactionDomain.createEvent.bind(explorerTransactionDomain);
export const defaultPrivateKey = "0x00000";
export const $privateKey = createStore<Hex>(defaultPrivateKey);
export const setPrivateKey = createEvent<Hex>();
export const initializePrivateKey = createEvent();
export const $wallet = createStore<WalletV1 | null>(null);
export const $endpoint = createStore<string>("");

export const $accountConnectorWithEndpoint = combine($privateKey, $endpoint, (privateKey, endpoint) => ({
  privateKey,
  endpoint,
}));

export const setEndpoint = createEvent<string>();

export const createWalletFx = explorerTransactionDomain.createEffect<
  {
    privateKey: Hex;
    endpoint: string;
  },
  WalletV1
>();

export const initilizeWallet = createEvent();

import { useUnit } from "effector-react";
import { $activeAppWithState, $contractWithState, choseApp, closeApp } from "./model";
import "./init";
import { BUTTON_KIND, Button, Card, Modal, SPACE, TAG_KIND, Tag } from "@nilfoundation/ui-kit";
import { useStyletron } from "baseui";
import { DeployForm } from "./DeployForm";
import { ContractManagement } from "./ContractManagement";

export const Contracts = () => {
  const [contracts, activeApp] = useUnit([$contractWithState, $activeAppWithState]);
  const [css] = useStyletron();
  return (
    <Card
      title="Contracts"
      overrides={{
        Root: {
          style: {
            backgroundColor: "#212121",
          },
        },
      }}
    >
      <Modal
        isOpen={!!activeApp}
        onClose={() => {
          closeApp();
        }}
        size={"80vw"}
      >
        {activeApp?.address ? <ContractManagement /> : <DeployForm />}
      </Modal>
      {contracts.map((contract) => {
        return (
          <Button
            kind={BUTTON_KIND.secondary}
            key={contract.bytecode}
            className={css({
              display: "flex",
              gap: SPACE[8],
              flexDirection: "row",
            })}
            onClick={() => {
              choseApp(contract.bytecode);
            }}
          >
            <span>{contract.name}</span>
            {contract.address ? <Tag kind={TAG_KIND.green}>Deployed</Tag> : <Tag kind={TAG_KIND.blue}>Compiled</Tag>}
          </Button>
        );
      })}
    </Card>
  );
};

import { createStore } from "effector";

export enum LayoutComponent {
  Code = 0,
  Contracts = 1,
  Logs = 2,
}

export const $activeComponents = createStore<LayoutComponent[]>([
  LayoutComponent.Code,
  LayoutComponent.Contracts,
  LayoutComponent.Logs,
]);

import { forward, sample } from "effector";
import {
  setPrivateKey,
  $privateKey,
  $endpoint,
  setEndpoint,
  createWalletFx,
  $wallet,
  $accountConnectorWithEndpoint,
  initilizeWallet,
  initializePrivateKey,
  defaultPrivateKey,
} from "./models/model";
import { persist } from "effector-storage/local";
import {
  Faucet,
  HttpTransport,
  LocalECDSAKeySigner,
  PublicClient,
  WalletV1,
  convertEthToWei,
  generateRandomPrivateKey,
} from "@nilfoundation/niljs";

persist({
  store: $endpoint,
  key: "endpoint",
});

persist({
  store: $privateKey,
  key: "privateKey",
});

$privateKey.on(setPrivateKey, (_, privateKey) => privateKey);
$endpoint.on(setEndpoint, (_, endpoint) => endpoint);

createWalletFx.use(async ({ privateKey, endpoint }) => {
  const signer = new LocalECDSAKeySigner({ privateKey });
  const client = new PublicClient({ transport: new HttpTransport({ endpoint }) });
  const pubkey = await signer.getPublicKey();
  const wallet = new WalletV1({
    pubkey,
    salt: 100n,
    shardId: 1,
    client,
    signer,
  });

  const balance = await wallet.getBalance();
  if (balance === 0n) {
    const faucet = new Faucet(client);
    await faucet.withdrawToWithRetry(wallet.getAddressHex(), convertEthToWei(0.1));
  }
  const code = await client.getCode(wallet.getAddressHex());
  if (code.length === 0) {
    await wallet.selfDeploy(true);
  }

  return wallet;
});

createWalletFx.failData.watch((error) => {
  console.error(error);
});

forward({
  from: $accountConnectorWithEndpoint,
  to: createWalletFx,
});

$wallet.reset($privateKey);
$wallet.on(createWalletFx.doneData, (_, wallet) => wallet);

sample({
  source: $accountConnectorWithEndpoint,
  clock: initilizeWallet,
  target: createWalletFx,
});

sample({
  clock: initializePrivateKey,
  filter: $privateKey.map((privateKey) => privateKey === defaultPrivateKey),
  fn: () => generateRandomPrivateKey(),
  target: setPrivateKey,
});

initializePrivateKey();

initilizeWallet();

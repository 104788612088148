import { useUnit } from "effector-react";
import { collapseLog, expandLog } from "./model";
import {
  BUTTON_KIND,
  BUTTON_SIZE,
  Button,
  COLORS,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  HeadingMedium,
  MonoParagraphMedium,
  SPACE,
} from "@nilfoundation/ui-kit";
import "./init";
import { $logsWithOpen } from "./init";
import { useStyletron } from "baseui";

export const Logs = () => {
  const [logs] = useUnit([$logsWithOpen]);
  const [css] = useStyletron();
  return (
    <Card
      title="Logs"
      overrides={{
        Root: {
          style: {
            backgroundColor: "#212121",
          },
        },
        Body: {
          style: {
            maxHeight: "50vh",
            overflow: "auto",
          },
        },
      }}
    >
      {logs.map((log, index) => {
        return (
          <div
            key={log.id}
            className={css({
              paddingBottom: SPACE[8],
              borderBottom: index === logs.length - 1 ? "none" : `1px solid ${COLORS.gray800}`,
              marginBottom: SPACE[8],
            })}
          >
            <div
              className={css({
                cursor: "pointer",
                marginBottom: log.isOpen ? SPACE[8] : 0,
              })}
              onMouseDown={(e) => {
                e.preventDefault();
                log.isOpen ? collapseLog(log.id) : expandLog(log.id);
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                log.isOpen ? collapseLog(log.id) : expandLog(log.id);
              }}
            >
              <HeadingMedium>{log.shortDescription}</HeadingMedium>
              <Button kind={BUTTON_KIND.secondary} size={BUTTON_SIZE.compact}>
                {log.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>
            {log.isOpen && (
              <Card
                overrides={{
                  Root: {
                    style: {
                      wordBreak: "break-all",
                    },
                  },
                }}
              >
                <MonoParagraphMedium>{JSON.stringify(log.payload, null, 2)}</MonoParagraphMedium>
              </Card>
            )}
          </div>
        );
      })}
    </Card>
  );
};

import {
  BUTTON_KIND,
  Button,
  COLORS,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  FormControl,
  Input,
  ModalBody,
  ModalHeader,
  ParagraphMedium,
  SPACE,
  Tab,
  Tabs,
} from "@nilfoundation/ui-kit";
import { useUnit } from "effector-react";
import { $activeAppWithState, $activeKeys, $managementKey, setManagementPage, toggleActiveKey } from "./model";
import { useMemo } from "react";
import type { AbiFunction } from "abitype";
import { useStyletron } from "baseui";

export const ContractManagement = () => {
  const [app, key, activeKeys] = useUnit([$activeAppWithState, $managementKey, $activeKeys]);
  const [css] = useStyletron();
  const readFunctions: AbiFunction[] = useMemo(() => {
    if (!app) {
      return [];
    }
    return app.abi.filter((abiField) => {
      return abiField.type === "function" && abiField.stateMutability === "view";
    }) as AbiFunction[];
  }, [app]);
  const writeFunctions = useMemo(() => {
    if (!app) {
      return [];
    }
    return app.abi.filter((abiField) => {
      return abiField.type === "function" && abiField.stateMutability !== "view";
    }) as AbiFunction[];
  }, [app]);
  return (
    <>
      <ModalHeader>Contract {app?.name}</ModalHeader>
      <ModalBody>
        <ParagraphMedium>Address: {app?.address}</ParagraphMedium>
        <Tabs
          activeKey={key}
          onChange={(page) => {
            setManagementPage(`${page.activeKey}`);
          }}
        >
          <Tab key="read" title="Read">
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                flexGrow: 0,
                flexShrink: 0,
                gap: SPACE[16],
              })}
            >
              {readFunctions.map((func, index) => {
                return (
                  <div
                    key={func.name}
                    className={css({
                      paddingBottom: SPACE[16],
                      borderBottom: `1px solid ${COLORS.gray800}`,
                      display: "flex",
                      flexDirection: "column",
                      flex: "0 0",
                    })}
                  >
                    <Button
                      kind={BUTTON_KIND.secondary}
                      className={css({
                        marginBottom: activeKeys[func.name] ? SPACE[16] : 0,
                        flexGrow: 0,
                      })}
                      endEnhancer={activeKeys[func.name] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                      onClick={() => {
                        toggleActiveKey(func.name);
                      }}
                    >
                      {index}. {func.name}
                    </Button>
                    {activeKeys[func.name] && (
                      <>
                        {func.inputs.length > 0 && (
                          <Card>
                            {func.inputs.map((input) => {
                              return (
                                <div key={input.name}>
                                  <FormControl label={input.name} caption={input.type}>
                                    <Input />
                                  </FormControl>
                                </div>
                              );
                            })}
                          </Card>
                        )}
                        <Button onClick={() => {}}>Call</Button>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </Tab>
          <Tab key="write" title="Write">
            {writeFunctions.map((func) => {
              return (
                <div key={func.name}>
                  <ParagraphMedium>{func.name}</ParagraphMedium>
                </div>
              );
            })}
          </Tab>
        </Tabs>
      </ModalBody>
    </>
  );
};

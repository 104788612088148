import type { StyleObject } from "styletron-react";
import { expandProperty } from "inline-style-expand-shorthand";
import { PRIMITIVE_COLORS, SPACE } from "@nilfoundation/ui-kit";
import { getTabletStyles } from "../../../../styleHelpers";

const linkOutlineStyles = {
  borderRadius: "4px",
  ...expandProperty("padding", "4px"),
  ":focus": {
    color: PRIMITIVE_COLORS.white,
    outline: `2px solid ${PRIMITIVE_COLORS.gray200}`,
  },
};

const container: StyleObject = {
  color: PRIMITIVE_COLORS.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "start",
  backgroundColor: "transparent",
  ...expandProperty("padding", "16px 48px 96px 48px"),
};

const content = {
  display: "grid",
  gridTemplateColumns: "180px 9fr 1fr",
  ...getTabletStyles({ gridTemplateColumns: "1fr 6fr" }),
  paddingTop: SPACE[48],
  gap: SPACE[32],
  width: "100%",
};

const navbar = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "48px",
};

const logo = {
  display: "flex",
  alignItems: "center",
  marginRight: "auto",
  ...linkOutlineStyles,
};

const navigation = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const navItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...expandProperty("padding", "16px 12px"),
  fontSize: "16px",
  fontWeight: 500,
  textWrap: "nowrap",
};

const navLink = {
  color: PRIMITIVE_COLORS.gray200,
  ...linkOutlineStyles,
};

export const styles = {
  container,
  logo,
  navbar,
  navigation,
  navItem,
  content,
  navLink,
};

export const mobileContainerStyle: StyleObject = {
  padding: "24px",
  display: "flex",
  flexDirection: "column",
};

export const mobileContentStyle = {
  paddingTop: "16px",
};

// biome-ignore lint/suspicious/noExplicitAny: it could be any options here
export const createCompileInput = (contractBody: string, options: any = {}): string => {
  const CompileInput = {
    language: "Solidity",
    sources: {
      Compiled_Contracts: {
        content: contractBody,
      },
    },
    settings: {
      outputSelection: {
        "*": {
          "*": ["*"],
        },
      },
      ...options,
    },
  };
  return JSON.stringify(CompileInput);
};

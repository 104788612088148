import { useStyletron } from "styletron-react";
import { styles } from "./styles";
import { COLORS, CopyButton, LabelMedium } from "@nilfoundation/ui-kit";
import { EndpointInput } from "./EndpointInput";

type AccountMenuProps = {
  address: string | null;
};

const AccountMenu = ({ address }: AccountMenuProps) => {
  const [css] = useStyletron();

  return (
    <ul className={css(styles.menu)} role="menu">
      <li className={css(styles.menuItem)}>
        <LabelMedium>{address}</LabelMedium>
        <CopyButton textToCopy={address === null ? "" : address} disabled={address === null} />
      </li>
      <li className={css(styles.divider)} />
      <li className={css(styles.menuItem)}>
        <EndpointInput />
      </li>
      <li className={css(styles.divider)} />
      <li className={css(styles.menuItem)}>
        <LabelMedium color={COLORS.gray400}>Sign out</LabelMedium>
      </li>
    </ul>
  );
};

// TODO - implement sign out with local storage clear

export { AccountMenu };
